@import "colors";

.header {
  position: relative;
  height: 95px;

  &__block {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      width: 1600px;
      height: 96px;
      border-bottom: 1px solid $border-color;
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      position: absolute;
      bottom: -1px;
      left: -200px;
      z-index: -1;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 300;
    @media screen and (max-width: 767px){
      font-size: 1.2rem;
    }

    &__img {
      margin-right: 10px;
    }
  }

  .nav {
    &__menu {
      width: 525px;
      display: flex;
      justify-content: space-between;
      margin-left: 40px;
    }

    &__menu-link {
      font-size: 1.6rem;
      font-weight: 300;
      transition: .3s;
      height: 93px;
      display: flex;
      align-items: center;
      position: relative;

      &:after {
        content: '';
        width: 0;
        height: 2px;
        background-color: $accent-color;
        position: absolute;
        bottom: 0;
      }

      &:hover {
        &:after {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 1199px) {
      display: none;
    }
  }

  .phone {
    width: 195px;
    height: 94px;
    border-left: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: relative;

    &__link {
      display: block;
      font-weight: 500;
      font-size: 1.6rem;
      letter-spacing: 1.5px;
      margin-bottom: 5px;
    }

    &:after {
      content: '';
      width: 1px;
      height: 95px;
      background-color: $border-color;
      position: absolute;
      right: -40px;
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }

    @media screen and (max-width: 1199px) {
      margin-left: auto;
      border: none;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  .menu-btn {
    display: none;
    @media screen and (max-width: 1199px) {
      display: block;
      width: 85px;
      height: 95px;
      margin-right: -20px;
      border-left: 1px solid $border-color;
      margin-left: 25px;
    }

    &__open {
      width: 45px;
      height: 50px;
      display: block;
      background-image: url("../img/hexagon.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      .line {
        width: 15px;
        height: 4px;
        display: block;
        background-color: $main-bg;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:after,
        &:before {
          content: '';
          width: 15px;
          height: 4px;
          display: block;
          background-color: $main-bg;
          position: absolute;
        }

        &:after {
          top: -8px;
          left: 0;
        }

        &:before {
          bottom: -8px;
          left: 0;
        }
      }
    }
  }

  .mobile {
    display: none;
    position: relative;
    transition: .3s;
    @media screen and (max-width: 1199px) {
      display: block;
      position: absolute;
      width: 768px;
      height: 0;
      overflow: hidden;
      top: 95px;
      left: -20px;
      background-color: $main-bg;
      z-index: 200;
      padding: 0 120px 0 60px;
      &.active {
        height: 77px;
        @media screen and (max-width: 767px){
          height: 385px;
          bottom: -385px;
        }
      }
      @media screen and (max-width: 767px){
        width: calc(100% + 40px);
        text-align: center;
        &.active {
          padding: 70px 0 30px;
        }
      }
    }

    &__nav {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.6rem;
      font-weight: 300;
      @media screen and (max-width: 767px){
        display: block;
        height: auto;
      }
    }

    &__nav-link {
      @media screen and (max-width: 767px){
        padding: 7px 0;
        margin: 5px 0;
        display: block;
      }
    }

    .mobile-close {
      width: 85px;
      height: 100%;
      display: block;
      border-left: 1px solid $border-color;
      position: absolute;
      right: 0;
      top: 0;

      &:after,
      &:before {
        content: '';
        width: 18px;
        height: 4px;
        display: block;
        background-color: $button-border;
        position: absolute;
        top: 50%;
        left: 33px;
      }
      &:before {
        transform: translateY(-50%) rotateZ(45deg);
      }
      &:after {
        transform: translateY(-50%) rotateZ(-45deg);
      }
      @media screen and (max-width: 767px){
        position: absolute;
        height: 60px;
        border-left: none;
      }
    }
    &__contact {
      display: none;
      @media screen and (max-width: 767px){
        display: block;
        font-size: 1.4rem;
        font-weight: 700;
        padding: 7px 0;
        margin: 5px 0;
      }
    }
    &__contact-link {
      display: block;
      font-size: 1.4rem;
      font-weight: 700;
      padding: 3px 0;
      margin: 1px 0;
    }
    &__contact-link:nth-child(4) {
      margin-top: 10px;
      font-weight: 400;
    }
  }
}