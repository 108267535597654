@import "colors";
@import "header";
@import "main";
@import "footer";


html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: 'Ubuntu', sans-serif;
  overflow-x: hidden;
  color: $text;
}

:root {
  font-size: 10px;
}

* {
  box-sizing: border-box;

  &:after,
  &:before {
    transition: inherit;
  }
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: $text;
}

ul, li {
  list-style: none;
}

button, input {
  outline: none;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  @media screen and (max-width: 1199px){
    max-width: 768px;
  }
  @media screen and (max-width: 767px){
    max-width: 425px;
  }
}

.button {
  font-size: 1.6rem;
  font-weight: 700;
  font-style: italic;
  padding: 15px 0;
  border: 2px solid $button-border;
  background-color: transparent;
  cursor: pointer;
  transition: .3s;
  &:hover {
    background-color: $button-border;
    color: $main-bg;
  }
}

.input {
  height: 44px;
  padding-left: 30px;
  width: 100%;
  display: block;
  margin-bottom: 20px;
  font-size: 1.6rem;
  border: 1px solid $border-color;

  &::placeholder {
    font-weight: 300;
  }
}

.input__file {
  display: none;
}

.btn-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-text {
  width: 163px;
  display: block;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 300;
  color: $button-border;
  position: relative;

  &:before {
    content: '+';
    color: $accent-color;
    font-size: 3.2rem;
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
  }
}

.slick-slide {
  outline: none;
}

.menu-bg {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 95px;
  left: 0;
  background-color: rgba(0, 0, 0, .8);
  z-index: 100;
}

.modal-bg {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .8);
  z-index: 100;
}

.modal-window {
  width: 93%;
  max-width: 540px;
  padding: 40px 35px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $main-bg;
  border: 1px solid $border-color;
  z-index: 200;

  .close-btn {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      width: 18px;
      height: 3px;
      display: block;
      background-color: $button-border;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &:before {
      transform: translate(-50%, -50%) rotateZ(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotateZ(-45deg);
    }
  }

  .headline {
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }

  .subtitle {
    font-size: 1.8rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
  }

}

.with-file {
  display: none;
  .button {
    width: 195px;
  }

  .btn-block {
    @media screen and (max-width: 525px) {
      display: block;
      .file-text {
        width: 100%;
        padding-right: 50px;

        &:before {
          right: 0;
        }
      }
      .button {
        width: 195px;
        margin: 20px auto 0;
        display: block;
      }
    }
  }
}

.without-file {
  display: none;
  .btn-block {
    display: block;

    .file-text {
      width: 100%;
      padding-right: 50px;

      &:before {
        right: 0;
      }
    }

    .button {
      width: 195px;
      margin: 20px auto 0;
      display: block;
    }
  }
}

.modal-send {
  display: none;
}

.button-ok {
  width: 150px;
  margin: 0 auto;
}

.invisible {
  opacity: 0;
}

.social-icon {
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
  transition: .3s;
  &:hover {
    box-shadow: 0 0 25px rgba(211, 41, 41,.3);
  }
}
