@import "colors";

.main {
  position: relative;
  height: 510px;
  @media screen and (max-width: 767px){
    height: auto;
    padding-bottom: 50px;
  }

  .container {
    position: relative;
    @media screen and (max-width: 767px){
      padding: 0;
    }
  }

  &__video {
    width: 840px;
    height: 510px;
    position: absolute;
    top: 0;
    right: -245px;
    @media screen and (max-width: 1199px){
      width: 384px;
      height: 510px;
      right: 0;
    }
    @media screen and (max-width: 767px){
      width: 100%;
      height: 385px;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }

  .headline-subtitle {
    @media screen and (max-width: 767px){
      position: absolute;
      top: 0;
    }
  }

  &__subtitle {
    display: inline-block;
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 5px;
    text-transform: uppercase;
    padding-right: 60px;
    margin-top: 60px;
    position: relative;
    z-index: 40;
    @media screen and (max-width: 1199px) {
      font-size: 2.4rem;
      padding-right: 20px;
      background-color: $main-bg;
    }
    @media screen and (max-width: 767px){
      font-size: 1.8rem;
      width: 180px;
      letter-spacing: 1px;
      padding-left: 20px;
      padding-right: 0;
    }

    &:after {
      content: '';
      width: 160px;
      height: 100%;
      display: block;
      background-color: $main-bg;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      @media screen and (max-width: 1199px){
        display: none;
      }
    }
  }

  &__headline {
    display: inline-block;
    font-size: 6rem;
    line-height: 7rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-right: 10px;
    margin-bottom: 30px;
    position: relative;
    z-index: 30;
    background-color: $main-bg;
    span {
      @media screen and (max-width: 767px){
        background-color: $main-bg;
        margin-left: -20px;
        padding-left: 20px;
        padding-top: 3px;
        padding-right: 10px;
      }
    }
    span:nth-child(1) {
      margin-left: 0;
      @media screen and (max-width: 767px){
        padding-left: 20px;
        margin-left: -20px;
      }
    }

    @media screen and (max-width: 1199px) {
      font-size: 4rem;
    }
    @media screen and (max-width: 767px){
      font-size: 2.4rem;
      line-height: normal;
      padding-left: 20px;
      margin-right: 30px;
      background-color: transparent;
    }
    &:after {
      content: '';
      width: 535px;
      height: 360px;
      display: block;
      border: 1px solid $border-color;
      border-top: none;
      background-color: transparent;
      position: absolute;
      top: -95px;
      right: -79px;
      z-index: -1;
      @media screen and (max-width: 1199px){
        display: none;
      }
    }
  }

  &__bullet {
    display: inline-block;
    padding: 0 20px;
    margin-bottom: 40px;
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 4.5rem;
    background-color: $button-border;
    color: $main-bg;
    position: relative;
    @media screen and (max-width: 767px){
      margin-top: 385px;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }

    &:before {
      content: '';
      width: 200px;
      height: 1px;
      display: block;
      background-color: $border-color;
      position: absolute;
      left: -200px;
      top: 50%;
      @media screen and (max-width: 767px){
        display: none;
      }
    }

    .hexagon {
      height: 46px;
      width: 46px;
      display: block;
      background-image: url("../img/hexagon.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: -223px;
      transform: translateY(-50%);
      z-index: 20;

      &:after {
        content: '';
        width: 30px;
        height: 30px;
        display: block;
        background-image: url("../img/dollar-white.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__description {
    font-size: 1.6rem;
    font-weight: 300;
    max-width: 300px;
    margin-bottom: 40px;
    @media screen and (max-width: 767px){
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  .button {
    width: 235px;
    @media screen and (max-width: 767px){
      margin-left: 20px;
    }
  }

  .button-call {
    width: 195px;
    height: 46px;
    border: 2px solid $button-border;
    background-color: $second-bg;
    position: absolute;
    z-index: 30;
    top: 0;
    right: 20px;
    font-size: 1.6rem;
    cursor: pointer;
    transition: .3s;
    &:hover {
      background-color: $button-border;
      color: $main-bg;
    }
    @media screen and (max-width: 1199px){
      right: 110px;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  &__decor-1 {
    width: 40px;
    height: 44px;
    display: block;
    right: -20px;
    background-color: $second-bg;
    position: absolute;
    top: 0;
    @media screen and (max-width: 1199px){
      width: 70px;
      right: 40px;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  &__decor-2 {
    width: 201px;
    height: 90px;
    right: -221px;
    display: block;
    background-color: $second-bg;
    position: absolute;
    top: 0;
    @media screen {
      right: -360px;
      width: 400px;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  &__decor-3 {
    width: calc((100vw - 1200px) / 2 - 220px);
    height: 510px;
    display: block;
    right: 0;
    background-color: $second-bg;
    position: absolute;
    top: 0;
    z-index: 20;
  }

  &__decor-4 {
    width: 50px;
    height: 55px;
    display: block;
    background-color: $second-bg;
    position: absolute;
    top: 455px;
    right: -220px;
    z-index: 20;
  }

  &__decor-5 {
    width: 535px;
    height: 360px;
    display: block;
    border-top: none;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 214px;
    z-index: 30;
    @media screen and (max-width: 1199px){
      width: 100%;
      height: 374px;
      left: 50%;
      transform: translateX(-50%);
    }

    .social-link {
      width: 46px;
      height: 46px;
      display: block;
      border-radius: 50%;
      background-color: $main-bg;
      position: absolute;
      bottom: -23px;
      @media screen and (max-width: 1199px){
        left: 50%;
      }

      &:after {
        content: '';
        width: 22px;
        height: 22px;
        display: block;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .whatsapp {
      left: 60px;
      @media screen and (max-width: 1199px){
        left: 290px;
      }

      &:after {
        background-image: url("../img/whatsapp.svg");
      }
    }

    .mail {
      left: 130px;
      @media screen and (max-width: 1199px){
        left: 360px;
      }

      &:after {
        background-image: url("../img/mail.svg");
      }
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  &__decor-6 {
    width: 1px;
    height: 510px;
    border-right: 1px solid $main-bg;
    display: block;
    position: absolute;
    top: 0;
    right: -20px;
    z-index: 20;

    &:before {
      content: '';
      height: 46px;
      width: 46px;
      display: block;
      background-image: url("../img/hexagon.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      bottom: -23px;
      left: -23px;
      z-index: 20;
    }

    &:after {
      content: '';
      height: 22px;
      width: 22px;
      display: block;
      background-image: url("../img/feedback-white.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      bottom: -9px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 30;
    }
    @media screen and (max-width: 1199px){
      display: none;
    }
  }

  &__decor-7 {
    width: calc((100% - 1200px) / 2 - 179px);
    height: 600px;
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  &__decor-8 {
    width: calc((100% - 1200px) / 2 - 179px);
    height: 60px;
    display: block;
    background-color: $second-bg;
    position: absolute;
    top: 450px;
    left: 0;
    z-index: 10;
  }

  &__decor-9 {
    width: 170px;
    height: 30px;
    display: block;
    background-color: $second-bg;
    position: absolute;
    top: 480px;
    left: -190px;
    z-index: 10;
    @media screen and (max-width: 767px){
      display: none;
    }
  }
}

.product {
  padding-top: 45px;
  padding-bottom: 70px;
  background-color: $second-bg;
  position: relative;

  .container {
    position: relative;
  }

  &__headline {
    padding-left: 100px;
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 5px;
    @media screen and (max-width: 1199px){
      font-size: 1.8rem;
    }
    @media screen and (max-width: 767px){
      padding-left: 0;
    }
  }

  &__subtitle {
    padding-left: 100px;
    font-size: 3rem;
    font-weight: 700;
    @media screen and (max-width: 1199px){
      font-size: 1.8rem;
      font-weight: 400;
    }
    @media screen and (max-width: 767px){
      padding-left: 0;
    }
  }

  &__tab {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 55px;
    margin-bottom: 35px;
    @media screen and (max-width: 767px){
      margin-top: 30px;
    }
  }

  &__tab-select {
    white-space: nowrap;
    flex-basis: 285px;
    @media screen and (max-width: 1199px){
      width: 260px;
      margin-bottom: 180px;
    }
    @media screen and (max-width: 767px){
      flex-basis: 100%;
      margin-bottom: 30px;
    }


    .select {
      padding: 15px 0;
      font-size: 1.6rem;
      font-weight: 300;
      cursor: pointer;
      position: relative;
      @media screen and (max-width: 1199px){
        font-size: 1.4rem;
      }

      &:after {
        content: '';
        width: 255px;
        height: 1px;
        border-bottom: 1px solid $border-color;
        position: absolute;
        bottom: 0;
        left: 0;
        @media screen and (max-width: 767px){
          width: 100%;
        }
      }

      &.active {
        font-weight: 700;
      }
    }
  }

  &__tab-image {
    flex-basis: 470px;
    position: relative;
    top: 0;
    @media screen and (max-width: 1199px){
      flex-basis: 410px;
      margin-bottom: 180px;
    }
    @media screen and (max-width: 767px){
      margin-bottom: 30px;
    }

    .frame-top {
      position: absolute;
      width: 100%;

      &:before,
      &:after {
        content: '';
        width: 50px;
        height: 50px;
        display: block;
        position: absolute;
        top: 0;
        border-top: 1px solid $border-color;
        @media screen and (max-width: 767px){
          display: none;
        }
      }

      &:before, {
        left: 0;
        border-left: 1px solid $border-color;
      }

      &:after, {
        right: 0;
        border-right: 1px solid $border-color;
      }
    }

    .frame-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;

      &:before,
      &:after {
        content: '';
        width: 50px;
        height: 50px;
        display: block;
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid $border-color;
        @media screen and (max-width: 767px){
          display: none;
        }
      }

      &:before, {
        left: 0;
        border-left: 1px solid $border-color;
      }

      &:after, {
        right: 0;
        border-right: 1px solid $border-color;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 260px;
        height: 210px;
      }
    }
  }

  &__tab-description {
    flex-basis: 325px;
    @media screen and (max-width: 1199px){
      flex-basis: 100%;
    }
    @media screen and (max-width: 767px){
      margin-bottom: 40px;
    }

    .description {
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 300;
      padding-right: 80px;
      height: 185px;
      overflow: auto;
      padding-bottom: 20px;
      margin-bottom: 10px;
      transition: .3s;
      @media screen and (max-width: 1199px){
        width: 100%;
        padding-right: 30px;
        height: auto;
      }
    }

    .bullet {
      padding-left: 30px;
      font-size: 1.4rem;
      font-weight: 700;
      margin-bottom: 20px;
      position: relative;
      @media screen and (max-width: 1199px){
        display: inline-block;
        margin-right: 40px;
      }
      @media screen and (max-width: 767px){
        display: block;
      }

      &:before {
        content: '';
        width: 8px;
        height: 10px;
        display: block;
        background-image: url("../img/hexagon-red.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        top: 2px;
        left: 0;
      }
    }

    .bullet:last-child {
      margin-bottom: 0;
      display: inline-block;
      cursor: pointer;

      &:before {
        background-image: url("../img/hexagon.svg");
      }

      &:after {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        background-color: $text;
      }
    }
  }

  &__btn-block {
    display: flex;
    justify-content: space-between;

    .button:first-child {
      width: 255px;
      @media screen and (max-width: 767px){
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .button:last-child {
      position: relative;
      width: 250px;
      @media screen and (max-width: 767px){
        width: calc(100% - 70px);
        margin-left: auto;
      }


      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
      }

      &:before {
        height: 100%;
        width: 73px;
        border: 2px solid $button-border;
        background-color: $button-border;
        left: -77px;
        top: -2px;
        @media screen and (max-width: 767px){
          width: 67px;
          left: -72px;
        }
      }

      &:after {
        width: 30px;
        height: 20px;
        background-image: url("../img/eye-white.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        top: 50%;
        left: -53px;
        transform: translateY(-50%);
      }
    }
    @media screen and (max-width: 1199px){
      width: 728px;
      position: absolute;
      top: 410px;
    }
    @media screen and (max-width: 767px){
      position: static;
      display: block;
      width: 100%;
      .button {
        display: block;
      }
    }
  }

  &__standard {
    font-size: 1.6rem;
    font-weight: 700;
    position: absolute;
    left: 345px;
    bottom: 12px;

    span {
      margin-right: 25px;
      font-size: 2.4rem;
      font-weight: 700;
      letter-spacing: 4px;
    }
    @media screen and (max-width: 1199px){
      position: absolute;
      top: 500px;
      left: 20px;
    }
    @media screen and (max-width: 767px){
      top: auto;
      bottom: 145px;
    }
  }

  &__decor-1 {
    width: 70px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../img/hexagon.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: -10px;
    left: 20px;
    color: $main-bg;
    font-size: 3rem;
    text-align: center;

    span {
      display: inline-block;
      font-size: 1.4rem;
    }

    @media screen and (max-width: 1199px){
      top: -17px;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  &__decor-2 {
    margin-top: 20px;
    font-size: 1.6rem;
    font-weight: 700;
    color: $border-color;
    letter-spacing: 3px;
    position: absolute;
    top: 265px;
    right: -161px;
    display: inline-block;
    padding: 0 30px;
    z-index: 10;
    transform: rotateZ(90deg);
    @media screen and (max-width: 1199px){
      top: 580px;
      right: -112px;
    }
    @media screen and (max-width: 767px){
      display: none;
    }

    &:before {
      content: '';
      width: 584px;
      height: 1px;
      display: block;
      background-color: $border-color;
      position: absolute;
      top: 50%;
      left: -180px;
      z-index: -2;
      @media screen and (max-width: 1199px){
        display: none;
      }
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background-color: $second-bg;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      @media screen and (max-width: 1199px){
        display: none;
      }
    }
  }

  &__decor-3 {
    width: calc((100% - 1200px) / 2 - 187px);
    height: 875px;
    display: block;
    border: 1px solid $border-color;
    border-right: none;
    position: absolute;
    right: 0;
    top: 125px;
    z-index: 10;
  }

  &__decor-4 {
    width: calc((100% - 1200px) / 2 - 187px);
    height: 60px;
    display: block;
    background-color: $main-bg;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__decor-5 {
    width: calc((100% - 1200px) / 2 - 40px);
    height: 30px;
    display: block;
    background-color: $main-bg;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__decor-6 {
    width: calc((100% - 1200px) / 2 - 92px);
    height: 740px;
    display: block;
    border: 1px solid $border-color;
    border-left: none;
    position: absolute;
    top: 530px;
    left: 0;
  }
}

.production {
  padding-top: 50px;
  padding-bottom: 130px;
  position: relative;
  @media screen and (max-width: 1199px){
    padding-bottom: 60px;
  }

  &:after {
    content: '';
    width: 909px;
    height: 1273px;
    display: block;
    background-image: url("../img/workshop.png");
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 10;
    @media screen  and (max-width: 1919px){
      right: calc((100% - 1920px) / 2);
    }
    @media screen and (max-width: 1199px){
      right: calc((100% - 1920px)/2 + 93px);
      background-size: contain;
      height: 905px;
      top: 280px;
    }
    @media screen and (max-width: 767px){
      right: calc((100% - 1920px)/2 + 220px);
      height: 930px;
      top: 406px;
    }
    @media screen and (max-width: 425px){
      height: 950px;
      top: 455px;
    }
  }

  .container {
    position: relative;

    &:before {
      content: '';
      width: 1292px;
      height: 15px;
      display: block;
      background-image: url("../img/decor-pr.png");
      position: absolute;
      top: 430px;
      right: 0;
      @media screen and (max-width: 425px){
        display: none;
      }
    }
  }

  &__headline {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 5px;
    @media screen and (max-width: 1199px){
      font-size: 1.8rem;
    }
  }

  &__subtitle {
    font-size: 1.8rem;
    font-weight: 700;
    position: relative;
    margin-bottom: 440px;
    @media screen and (max-width: 1199px){
      font-size: 1.8rem;
      font-weight: 400;
      max-width: 500px;
    }
    @media screen and (max-width: 767px){
      margin-bottom: 30px;
    }

    &:after {
      content: '';
      width: 27px;
      height: 6px;
      display: block;
      background-color: $accent-color;
      position: absolute;
      bottom: -15px;
      left: 0;
      @media screen and (max-width: 1199px){
        bottom: -25px;
      }
    }
  }

  .scroll {
    position: absolute;
    top: 100px;
    height: 300px;
    @media screen and (max-width: 1199px){
      width: 768px;
      overflow-x: scroll;
      .scroll-block {
        width: 1200px;
      }
    }
    @media screen and (max-width: 767px){
      width: 100%;
      position: relative;
      top: 0;
      margin-bottom: 30px;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: -20px;
    width: 1240px;
  }

  .decor {
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    cursor: pointer;
    z-index: 20;
    &.active {
      color: $accent-color;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
    }
  }

  &__decor-1 {
    top: 250px;
    left: 50px;
    width: 200px;

    &:before {
      height: 60px;
      width: 1px;
      border-left: 1px solid $second-border;
      top: -70px;
      left: 15px;
    }
  }

  &__decor-2 {
    top: 20px;
    left: 350px;
    width: 250px;

    &:before {
      width: 120px;
      height: 107px;
      border-top: 1px solid $second-border;
      border-left: 1px solid $second-border;
      top: 10px;
      left: -135px;
    }
  }

  &__decor-3 {
    width: 250px;
    top: 250px;
    left: 645px;

    &:before {
      width: 100px;
      height: 100px;
      border-left: 1px solid $second-border;
      border-bottom: 1px solid $second-border;
      bottom: 10px;
      left: -120px;
    }
  }

  &__decor-4 {
    top: 40px;
    left: 900px;
    width: 185px;

    &:before {
      height: 77px;
      width: 1px;
      border-left: 1px solid $second-border;
      right: -10px;
      top: 20px;
    }
  }

  &__description {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    font-weight: 300;
    @media screen and (max-width: 767px){
      display: block;
    }
  }

  &__description-left {
    flex-basis: 530px;
    @media screen and (max-width: 1199px){
      flex-basis: 49%;
    }
    @media screen and (max-width: 767px){
      margin-bottom: 30px;
    }
  }

  &__description-right {
    flex-basis: 570px;
    transition: .3s;
    @media screen and (max-width: 1199px){
      flex-basis: 49%;
    }
  }
}

.offer {
  padding-top: 40px;
  padding-bottom: 35px;
  background-color: $second-bg;
  position: relative;

  .container {
    position: relative;
  }

  &__headline {
    padding-left: 100px;
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 5px;
    @media screen and (max-width: 1199px){
      font-size: 1.8rem;
    }
    @media screen and (max-width: 767px){
      padding-left: 0;
    }
  }

  &__subtitle {
    padding-left: 100px;
    font-size: 3rem;
    font-weight: 700;
    @media screen and (max-width: 1199px){
      font-size: 1.8rem;
      font-weight: 400;
    }
    @media screen and (max-width: 767px){
      padding-left: 0;
    }
  }

  &__decor-1 {
    width: 70px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../img/hexagon.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: -10px;
    left: 20px;
    color: $main-bg;
    font-size: 3rem;
    text-align: center;
    @media screen and (max-width: 1199px){
      top: -17px;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  .image {
    position: absolute;
    left: -50px;
    top: 90px;
    @media screen and (max-width: 1199px){
      display: none;
    }
  }

  &__form {
    width: 100%;
    max-width: 540px;
    padding: 40px 30px;
    background-color: $main-bg;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    position: relative;
    z-index: 10;
    margin-top: 40px;
    margin-left: 125px;
    .button {
      width: 195px;
    }
    @media screen and (max-width: 1199px){
      margin-left: 0;
    }
    @media screen and (max-width: 767px){
      .btn-block {
        display: block;
        .file-text {
          width: 100%;
          padding-right: 30px;
          margin-bottom: 20px;
          &:before {
            right: 0;
          }
        }
        .button {
          width: 100%;
        }
      }
    }
  }

  &__decor-2 {
    width: calc((100% - 1200px) / 2 - 20px);
    height: 35px;
    display: block;
    background-color: $second-bg;
    position: absolute;
    left: -20px;
    top: -35px;
  }

  &__decor-3 {
    width: calc((100% - 1200px) / 2 - 120px);
    height: 30px;
    display: block;
    background-color: $second-bg;
    position: absolute;
    left: -120px;
    top: -60px;
  }
}

.work {
  padding-top: 50px;
  padding-bottom: 90px;
  position: relative;
  @media screen and (max-width: 1199px){
    padding-bottom: 0;
  }

  &:after {
    content: '';
    width: 415px;
    height: 600px;
    display: block;
    border: 1px solid $border-color;
    border-bottom: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    @media screen and (max-width: 1199px){
      height: 700px;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  &__headline {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    margin-bottom: 60px;
    @media screen and (max-width: 1199px){
      font-size: 1.8rem;
    }
    @media screen and (max-width: 767px){
      margin-bottom: 100px;
    }

    &:after {
      content: '';
      width: 27px;
      height: 6px;
      display: block;
      background-color: $accent-color;
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 130px;
    @media screen and (max-width: 1199px){
      margin-bottom: 0;
    }
    @media screen and (max-width: 767px){
      position: relative;
    }
  }

  .mb-0 {
    margin-bottom: 0;
  }

  &__card-item {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    width: 372px;
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
    @media screen and (max-width: 767px){
      width: 125px;
    }

    .hex {
      height: 122px;
      width: 107px;
      background-image: url("../img/hex-1.png");
      background-size: contain;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s;
      &:after {
        content: '';
        width: 30px;
        height: 1px;
        display: none;
        background-color: $border-color;
        position: absolute;
        @media screen and (max-width: 1199px){
          display: block;
        }
      }
      @media screen and (max-width: 1199px){
        margin-bottom: 20px;
      }

      .icon {
        height: 40px;
        width: 40px;

        svg {
          height: 100%;
          width: 100%;
        }

        .fil {
          fill: $button-border;
          transition: .3s;
        }

        position: relative;
        z-index: 10;
      }

      &:hover {

      }
    }

    &:hover {
      .hex {
        background-image: url("../img/hex-2.png");

        .icon {
          .fil {
            fill: $main-bg;
          }
        }
      }
    }

    .description {
      width: 248px;
      border-bottom: 1px solid $border-color;
      padding-bottom: 10px;
      margin-bottom: 30px;
      @media screen and (max-width: 1199px){
        border: none;
        width: 160px;
        font-size: 1.4rem;
        font-weight: 500;
      }
      @media screen and (max-width: 767px){
        width: 100%;
      }
    }

    &.active {
      .hex {
        background-image: url("../img/hex-2.png");
        .icon {
          .fil {
            fill: $main-bg;
          }
        }
      }
    }
  }

  &__card-item:nth-child(1) {
    .description {
      padding-right: 55px;
    }
    @media screen and (max-width: 1199px){
      flex-direction: column;
      justify-content: normal;
      align-items: flex-start;

      .description {
        padding-right: 20px;
      }
      .hex {
        &:after {
          top: 90px;
          right: -50px;
          @media screen and (max-width: 767px){
            display: none;
          }
        }
      }
    }
  }

  &__card-item:nth-child(2) {
    .description {
      text-align: right;
      padding-left: 55px;
    }
    @media screen and (max-width: 1199px){
      flex-direction: column;
      justify-content: normal;
      align-items: flex-end;
      .description {
        padding-left: 0;
      }
      .hex {
        order: -1;
        &:after {
          top: 90px;
          left: -50px;
          @media screen and (max-width: 767px){
            display: none;
          }
        }
      }
    }
  }

  &__description {
    width: 540px;
    position: absolute;
    top: 135px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    @media screen and (max-width: 767px){
      position: static;
      width: 100%;
      transform: none;
    }

    .headline {
      font-size: 2.4rem;
      font-weight: 700;
      margin-bottom: 15px;
      text-align: center;
      @media screen and (max-width: 767px){
        position: absolute;
        top: 160px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }

    .description {
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-weight: 300;
      max-width: 360px;
      margin: 0 auto;
      transition: .3s;
      @media screen and (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    .image {
      position: absolute;
      top: 180px;
      width: 547px;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 1199px){
        width: 350px;
        top: 280px;
      }
      @media screen and (max-width: 767px){
        width: 100%;
        position: static;
        transform: none;
        margin-bottom: 30px;
      }
    }
  }
  &__decor-line {
    display: none;
    @media screen and (max-width: 767px){
      width: 30px;
      height: 1px;
      display: block;
      background-color: $border-color;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 63px;
    }
  }
}

.sentence {
  position: relative;
  padding-top: 60px;
  padding-bottom: 110px;
  &:after {
    content: '';
    width: 100%;
    height: 325px;
    display: block;
    background-color: $second-bg;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: -1;
    @media screen and (max-width: 767px){
      height: 190px;
      top: auto;
      bottom: 0;
    }
  }
  .container {
    position: relative;
    @media screen and (max-width: 1199px){
      margin-top: -35px;
    }
    @media screen and (max-width: 767px){
      margin-top: 0;
      padding: 0;
    }
  }
  &__phone {
    display: flex;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 20px;
    @media screen and (max-width: 1199px){
      flex-direction: column;
      align-items: flex-start;
      margin-top: 60px;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  &__phone-link {
    margin-right: 110px;
  }
  .link {
    display: block;
    margin-bottom: 5px;
    font-size: 1.6rem;
  }
  &__icon {
    width: 46px;
    height: 46px;
    display: block;
    border-radius: 50%;
    background-color: $main-bg;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    position: relative;


  }
  .whatsapp {
    position: relative;
    @media screen and (max-width: 1199px){
      order: -1;
      margin-bottom: 10px;
    }
    &:after {
      content: '';
      width: 22px;
      height: 22px;
      display: block;
      background-image: url("../img/whatsapp.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:before {
      content: '';
      width: 72px;
      height: 1px;
      display: block;
      background-color: $border-color;
      position: absolute;
      top: 50%;
      left: -90px;
      transform: translateY(-50%);
      @media screen and (max-width: 1199px){
        display: none;
      }
    }
  }

  &__main {
    display: flex;
    align-items: center;
    @media screen and (max-width: 1199px){
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }
  .mail {
    margin-right: 110px;
    position: relative;
    @media screen and (max-width: 1199px){
      margin-bottom: 10px;
    }

    &:after {
      content: '';
      width: 22px;
      height: 22px;
      display: block;
      background-image: url("../img/mail.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:before {
      content: '';
      width: 72px;
      height: 1px;
      display: block;
      background-color: $border-color;
      position: absolute;
      top: 50%;
      right: -90px;
      transform: translateY(-50%);
      @media screen and (max-width: 1199px){
        display: none;
      }
    }
  }

  &__headline {
    font-size: 3rem;
    font-weight: 700;
    padding: 10px 30px;
    background-color: $main-bg;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 1199px){
      font-size: 1.8rem;
      padding-bottom: 5px;
    }
    @media screen and (max-width: 767px) {
      padding: 0;
      text-align: center;
    }
  }

  &__form {
    width: 415px;
    padding: 0 40px 40px 40px;
    background-color: $main-bg;
    border: 1px solid $border-color;
    border-top: none;
    position: absolute;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 1199px){
      top: 35px;
    }
    @media screen and (max-width: 767px){
      position: static;
      transform: none;
      padding: 20px;
      width: 100%;
      border: none;
      margin-bottom: 100px;
    }
    .headline {
      font-size: 1.8rem;
      font-weight: 700;
      text-align: center;
      margin-top: -5px;
      margin-bottom: 40px;
    }
  }
  .btn-block {
    display: flex;
    flex-wrap: wrap;
    .input__file-button{
      width: 100%;
      margin-bottom: 20px;
    }
    .file-text {
      width: 100%;
      padding-right: 170px;
      min-height: 40px;
      &:before {
        right: 0;
      }
    }
    .button {
      width: 195px;
      margin: 0 auto;
    }
  }

  &__decor-1 {
    width: 664px;
    height: 536px;
    display: block;
    background-image: url("../img/machine.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: -120px;
    right: -350px;
    @media screen and (max-width: 1919px){
      right: calc(((1920px - 100%) / 2) * -1);
    }
    @media screen and (max-width: 1919px){
      right: calc(((1920px - 100%) / 2) * -1 + 70px);
      z-index: 10;
    }
    @media screen and (max-width: 767px){
      width: 320px;
      height: 250px;
      top: auto;
      bottom: -245px;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
  }
  &__decor-2 {
    width: calc((100% - 1200px) / 2 + 170px);
    height: 35px;
    display: block;
    background-color: $second-bg;
    position: absolute;
    top: 25px;
    left: 0;
    @media screen and (max-width: 1199px){
      width: calc((100% - 1200px) / 2 + 320px);
    }
  }
  &__decor-3 {
    width: 55px;
    height: 35px;
    display: block;
    background-color: $second-bg;
    position: absolute;
    top: -35px;
    right: 253px;
    @media screen and (max-width: 1199px){
      right: -400px;
      width: 500px;
      top: 0;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }
  &__decor-4 {
    width: calc((100% - 1200px) / 2 - 10px);
    height: 35px;
    display: block;
    background-color: $main-bg;
    position: absolute;
    bottom: 60px;
    left: 0;
  }
  &__decor-5 {
    width: 200px;
    height: 35px;
    display: block;
    background-color: $main-bg;
    position: absolute;
    bottom: -15px;
    left: -300px;
    @media screen and (max-width: 1199px){
      left: 138px;
      bottom: auto;
      top: 35px;
      width: 40px;
      height: 45px;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }
}

.awards {
  padding-top: 80px;
  padding-bottom: 110px;
  @media screen and (max-width: 1199px){
    padding-bottom: 50px;
  }
  .container {
    position: relative;
  }
  &__card {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1199px){
      justify-content: space-between;
    }
  }
  &__card-item {
    display: flex;
    align-items: flex-start;
    margin-right: 80px;
    position: relative;
    @media screen and (max-width: 1199px){
      width: 340px;
      margin-right: 0;
    }
    @media screen and (max-width: 767px){
      width: 100%;
    }
    .headline {
      font-size: 2rem;
      font-weight: 700;
      width: 371px;
      margin-bottom: 30px;
      @media screen and (max-width: 1199px){
        width: 212px;
      }
    }
    .description,
    .description-2 {
      font-size: 1.4rem;
      line-height: 1.7rem;
      font-weight: 300;
    }
  }
  &__card-item:nth-child(1) {
    margin-bottom: 155px;
    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: $border-color;
      position: absolute;
      right: 20px;
      top: 0;
      display: none;
      @media screen and (max-width: 767px){
        display: block;
      }
    }
    &:before {
      content: '';
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: $border-color;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      @media screen and (max-width: 767px){
        display: block;
      }
    }
    .company-name {
      width: 277px;
      @media screen and (max-width: 1199px){
        width: 212px;
      }
    }
    @media screen and (max-width: 1199px){
      margin-bottom: 80px;
    }
  }
  &__card-item:nth-child(2) {
    margin-right: 0;
    margin-bottom: 155px;
    &:after {
      content: '';
      width: 1px;
      height: 960px;
      background-color: $border-color;
      position: absolute;
      left: 42px;
      z-index: -1;
      display: none;
      @media screen and (max-width: 767px){
        display: block;
      }
    }
    &:before {
      content: '';
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: $border-color;
      position: absolute;
      top: 180px;
      left: 37px;
      z-index: -1;
      display: none;
      @media screen and (max-width: 767px){
        display: block;
      }
    }
    .headline {
      width: 371px;
      @media screen and (max-width: 1199px){
        width: 212px;
      }
      @media screen and (max-width: 767px){
        width: 180px;
      }
    }
    .description {
      width: 371px;
      @media screen and (max-width: 1199px){
        width: 212px;
      }
      @media screen and (max-width: 767px){
        width: 180px;
      }
    }
    @media screen and (max-width: 1199px){
      margin-bottom: 80px;
    }
    @media screen and (max-width: 767px){
      margin-bottom: 40px;
    }
  }
  &__card-item:nth-child(3) {
    &:before {
      content: '';
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: $border-color;
      position: absolute;
      top: 190px;
      left: 37px;
      z-index: -1;
      display: none;
      @media screen and (max-width: 767px){
        display: block;
      }
    }
    .headline {
      width: 340px;
      @media screen and (max-width: 1199px){
        width: 212px;
      }
      @media screen and (max-width: 767px){
        width: 180px;
      }
    }
    .description {
      width: 340px;
      @media screen and (max-width: 1199px){
        width: 212px;
      }
      @media screen and (max-width: 767px){
        width: 180px;
      }
    }
    @media screen and (max-width: 767px){
      margin-bottom: 40px;
    }
  }
  &__card-item:nth-child(4) {
    margin-right: 0;
    position: relative;
    &:before {
      content: '';
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: $border-color;
      position: absolute;
      top: 230px;
      left: 37px;
      z-index: -1;
      display: none;
      @media screen and (max-width: 767px){
        display: block;
      }
    }
    .headline {
      width: 310px;
      @media screen and (max-width: 1199px){
        width: 212px;
      }
      @media screen and (max-width: 767px){
        width: 180px;
      }
    }
    .description {
      width: 310px;
      border-right: 1px solid $border-color;
      @media screen and (max-width: 1199px){
        width: 212px;
        border-right: none;
      }
      @media screen and (max-width: 767px){
        width: 180px;
      }

    }
    .description-2 {
      position: absolute;
      top: 74px;
      right: -192px;
      @media screen and (max-width: 1199px){
        position: static;
        margin-top: 20px;
      }
    }
    @media screen and (max-width: 767px){
      margin-bottom: 40px;
    }
  }
  .image.logo {
    margin-right: 0;
    width: 153px;
    height: 153px;
    box-shadow: none;
    @media screen and (max-width: 767px){
      width: 85px;
      height: 85px;
    }
  }
  .image {
    margin-right: 30px;
    width: 100px;
    height: 100px;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    @media screen and (max-width: 767px){
      width: 85px;
      height: 85px;
      flex-shrink: 0;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .company-name {
    font-size: 4.8rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 40px;
    @media screen and (max-width: 767px){
      font-size: 2.4rem;
    }
  }
  &__decor-1 {
    width: 11px;
    height: 11px;
    display: block;
    border-radius: 50%;
    background-color: $second-bg;
    position: absolute;
    top: 215px;
    left: 20px;
    &:after {
      content: '';
      width: 10000px;
      height: 1px;
      display: block;
      background-color: $second-bg;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      @media screen and (max-width: 767px){
        display: none;
      }
    }
    @media screen and (max-width: 1199px){
      top: 245px;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }
  &__decor-2 {
    width: 11px;
    height: 11px;
    display: block;
    border-radius: 50%;
    background-color: $second-bg;
    position: absolute;
    top: 215px;
    left: 610px;
    &:after {
      content: '';
      width: 1px;
      height: 221px;
      display: block;
      background-color: $second-bg;
      position: absolute;
      top: -116px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    @media screen and (max-width: 1199px){
      top: 245px;
      left: 450px;
      &:after {
        height: 188px;
        top: -145px;
      }
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }
}

.clients {
  padding-bottom: 45px;
  background-color: $second-bg;
  position: relative;
  .container {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  &__description {
    padding-top: 235px;
    width: 351px;
    margin-right: 30px;
    @media screen and (max-width: 1199px){
      width: 100%;
      padding-top: 70px;
    }
    @media screen {
      margin-right: 0;
    }
    .headline {
      font-size: 3rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 20px;
      @media screen and (max-width: 1199px){
        font-size: 1.8rem;
        max-width: 396px;
        margin-left: 220px;
        position: relative;
        z-index: 10;
        margin-bottom: 425px;
      }
      @media screen and (max-width: 767px){
        margin-left: 0;
        margin-bottom: 30px;
      }
    }
    .map {
      width: 727px;
      height: 400px;
      background-image: url("../img/map.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      top: 80px;
      right: 0;
      @media screen and (max-width: 1199px){
        top: 135px;
      }
      @media screen and (max-width: 767px){
        position: relative;
        top: 0;
        width: 100%;
        height: calc(100vw * 0.55);
        max-height: 235px;
        background-image: url("../img/map-mobile.png");
      }
      &__city {
        color: $main-bg;
        font-size: 1.4rem;
        font-weight: 300;
        position: absolute;
        transition: .3s;
        cursor: pointer;
        &.active {
          font-weight: 700;
          color: $accent-color;
        }
        &:hover {
          font-weight: 700;
          color: $accent-color;
        }
        @media screen and (max-width: 767px){
          display: none;
        }
      }
      &__city:nth-child(1) {
        top: 200px;
        left: 100px;
      }
      &__city:nth-child(2) {
        top: 160px;
        left: 168px;
      }
      &__city:nth-child(3) {
        top: 276px;
        left: 327px;
      }
      &__city:nth-child(4) {
        top: 53px;
        left: 346px;
      }
      &__city:nth-child(5) {
        top: 130px;
        left: 377px;
      }
      &__city:nth-child(6) {
        top: 295px;
        left: 467px;
      }
      &__city:nth-child(7) {
        top: 195px;
        left: 480px;
      }
      &__city:nth-child(8) {
        top: 90px;
        left: 486px;
      }
      &__city:nth-child(9) {
        top: 150px;
        left: 580px;
      }
    }
    .company {
      transition: .3s;
      position: relative;
      @media screen and (max-width: 1199px){
        width: 100%;
        display: flex;
        margin-bottom: 50px;
      }
      @media screen and (max-width: 767px){
        display: block;
      }
      .next-region {
        display: none;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 3px;
        right: 20px;
        &:before {
          content: "";
          width: 15px;
          height: 15px;
          display: block;
          border-bottom: 2px solid #000;
          border-left: 2px solid #000;
          position: absolute;
          top: 6px;
          left: 2px;
          transform: rotateZ(-135deg);
        }
        @media screen and (max-width: 767px){
          display: block;
        }
      }
    }
    .region-city {
      width: 272px;
      font-size: 1.6rem;
      font-weight: 300;
      @media screen and (max-width: 1199px){
        width: 365px;
        margin-right: 100px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .region {
      padding: 10px 0;
      border-bottom: 1px solid $border-color;
    }
    .city {
      padding: 10px 0;
      border-bottom: 1px solid $border-color;
    }
    .company-list {
      margin-top: 20px;
      width: 272px;
      height: 100px;
      padding: 0 20px 10px 0;
      overflow-y: auto;
      @media screen and (max-width: 1199px){
        height: 70px;
      }
      &__item {
        font-size: 1.6rem;
        font-weight: 300;
        margin-bottom: 5px;
      }
    }

  }
  &__slider {
    width: 525px;
    margin-bottom: -10px;
    .slider-item {
      display: flex;
      align-items: center;
      width: 170px;
      height: 170px;
      margin-right: 10px;
    }
    .full-image {
      width: 150px;
      height: 150px;
      box-shadow: 0 0 10px rgba(0,0,0, .3);
      display: block;
      outline: none;
    }
    .slide {
      height: 100%;
      width: 100%;
      object-fit: cover;
      outline: none;
    }
    .slick-prev,
    .slick-next {
      width: 30px;
      height: 30px;
      &:before {
        content: '';
        width: 15px;
        height: 15px;
        display: block;
        border-bottom: 2px solid $text;
        border-left: 2px solid $text;
        position: absolute;
        top: 50%;
        left: 50%;

      }
    }
    .slick-prev {
      left: auto;
      right: -30px;
      &:before {
        transform: translate(-50%, -50%) rotateZ(45deg);
      }
    }
    .slick-next {
      left: auto;
      right: -80px;
      &:before {
        transform: translate(-50%, -50%) rotateZ(-135deg);
      }
    }
    @media screen and (max-width: 1199px){
      width: 840px;
      margin-bottom: -130px;
    }
    @media screen and (max-width: 767px){
      margin-bottom: 0;
    }
  }

  &__decor-1 {
    width: 192px;
    height: 221px;
    display: block;
    background-image: url("../img/hex-big.png");
    position: absolute;
    top: -2px;
    left: 20px;
    z-index: 10;
    &:before {
      content: '';
      width: 75px;
      height: 50px;
      display: block;
      background-image: url("../img/icon-4.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }
  &__decor-2 {
    width: 225px;
    height: 35px;
    display: block;
    background-color: $main-bg;
    position: absolute;
    top: 0;
    left: 0;
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  &__decor-3 {
    width: 285px;
    height: 35px;
    display: block;
    background-color: $main-bg;
    position: absolute;
    top: 0;
    left: 50%;
    @media screen and (max-width: 1199px){
      left: 78%;
    }
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  &__decor-4 {
    width: 160px;
    height: 35px;
    display: block;
    background-color: $main-bg;
    position: absolute;
    top: 35px;
    left: 663px;
    @media screen and (max-width: 767px){
      display: none;
    }
  }

  &__decor-5 {
    width: calc((100% - 1200px) / 2 - 80px);
    height: 35px;
    display: block;
    background-color: $main-bg;
    position: absolute;
    bottom: 0;
    left: 0;
    @media screen and (max-width: 767px){
      display: none;
    }
  }
  &__decor-6 {
    width: calc((100% - 1200px) / 2 - 160px);
    height: 35px;
    display: block;
    background-color: $main-bg;
    position: absolute;
    bottom: 35px;
    left: 0;
    @media screen and (max-width: 767px){
      display: none;
    }
  }
  &__decor-7 {
    width: 10000px;
    height: 170px;
    border-left: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    position: absolute;
    top: 0;
    left: 393px;
    @media screen and (max-width: 767px){
      display: none;
    }
  }
}