@import "colors";

.footer {
  padding-top: 120px;
  @media screen and (max-width: 1199px){
    padding-top: 160px;
  }
  @media screen and (max-width: 767px){
    padding-top: 50px;
  }

  &__action {
    width: 100%;
    height: 45px;
    box-shadow: 0 0 10px rgba(0,0,0,.4);
    background-color: $main-bg;
    position: fixed;
    bottom: -60px;
    left: 0;
    z-index: 50;
    display: flex;
    transition: .3s;
    .whatsapp {
      &:after {
        background-image: url("../img/whatsapp.svg");
      }

    }
    .mail {
      &:after {
        background-image: url("../img/mail.svg");
      }

    }
    .phone {
      &:after {
        background-image: url("../img/phone.svg");
      }
    }
    &.active {
      bottom: 0;
    }
  }

  &__action-icon {
    height: 100%;
    flex-basis: 33.33%;
    position: relative;
    &:after {
      content: '';
      width: 25px;
      height: 25px;
      display: block;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .container {
    position: relative;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__contact {
    font-size: 1.6rem;
    font-weight: 300;
    width: 334px;
    @media screen and (max-width: 1199px){
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 767px){
      flex-wrap: wrap;
    }

    .link {
      display: block;
    }
  }

  &__contact-address {
    width: 177px;
    margin-bottom: 20px;
    line-height: 2.1rem;
    @media screen and (max-width: 767px){
      width: 49%;
    }
  }

  &__contact-phone {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1199px){
      order: 3;
      flex-wrap: wrap;
      height: 50px;
      width: 250px;
      .link {
        margin-right: 10px;
      }
    }
    @media screen and (max-width: 767px){
      width: 49%;
      height: auto;
      order: 2;
    }
    .link {
      display: inline-block;
      margin-bottom: 3px;
    }
  }

  &__contact-mail {
    margin-bottom: 20px;
    @media screen and (max-width: 1199px){
      order: 2;
    }
    @media screen and (max-width: 767px){
      width: 49%;
      order: 3;
    }
  }

  &__contact-whatsapp {
    width: 46px;
    height: 46px;
    display: block;
    border-radius: 50%;
    background-color: $main-bg;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    position: relative;
    &:after {
      content: '';
      width: 22px;
      height: 22px;
      display: block;
      background-image: url("../img/whatsapp.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @media screen and (max-width: 1199px){
      order: 4;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__certificate {
    width: 270px;
    @media screen and (max-width: 1199px){
      width: 100%;
      display: flex;
    }
    @media screen and (max-width: 767px){
      display: block;
    }

    .description {
      font-size: 1.6rem;
      margin-bottom: 15px;
      font-weight: 700;
      @media screen {
        width: 285px;
      }
      @media screen and (max-width: 767px){
        width: 100%;
        border-top: 1px solid $border-color;
        padding-top: 20px;
      }
    }
  }

  &__certificate-list {
    height: 230px;
    width: 220px;
    overflow-y: auto;
    @media screen and (max-width: 1199px){
      display: flex;
      width: 570px;
      overflow-y: hidden;
      overflow-x: scroll;
      margin-left: 80px;
    }
    @media screen and (max-width: 767px){
      width: 100%;
      margin-left: 0;
    }
  }

  &__certificate-item {
    margin-bottom: 5px;
    @media screen and (max-width: 1199px) {
      margin-right: 10px;
    }

    .image-full {

    }
    .image {

    }
  }

  &__callback {
    width: 334px;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 65px;
    left: 20px;
    .button {
      width: 125px;
      @media screen and (max-width: 1199px){
        margin-top: 20px;
      }
      @media screen and (max-width: 767px){
        display: block;
        margin: 20px auto 0;
      }
    }
    @media screen and (max-width: 1199px){
      width: 285px;
      padding-top: 30px;
      flex-direction: column;
      justify-content: normal;
      align-items: flex-start;
      border-top: 1px solid $border-color;
      position: static;
      margin-top: -140px;
    }
    @media screen and (max-width: 767px){
      display: block;
      width: 100%;
      padding-top: 0;
      margin-top: 20px;
      border-top: none;
      text-align: center;
    }
  }

  &__map {
    width: 485px;
    background-image: url("../img/footer_map1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 1199px){
      width: 100%;
      height: 300px;
      margin-top: 50px;
      margin-bottom: 20px;
      background-image: url("../img/footer_map1.jpg");
    }
    @media screen and (max-width: 767px){
      background-image: url("../img/footer_map1.jpg");
      background-position: center;
    }
  }


  &__made {
    border-top: 1px solid $border-color;
    padding: 15px 0;
    margin-top: 15px;
    text-align: center;
    font-size: 1.4rem;
  }
}